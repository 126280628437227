<template>
	<v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition" @input="$emit('close')">
		<v-card>
			<v-toolbar color="primary" dark>
				<v-toolbar-title>{{ value.name }}</v-toolbar-title>
				<w-spacer />
				<v-toolbar-items>
					<w-btn icon="zoom_in" mini @click="zoomIn">{{ $t('actions.zoom_in') }}</w-btn>
					<w-btn icon="zoom_out" mini @click="zoomOut">{{ $t('actions.zoom_out') }}</w-btn>
					<w-btn icon="close" mini @click="closeFilePreview()">{{ $t('actions.close') }}</w-btn>
				</v-toolbar-items>
			</v-toolbar>

			<div class="pdf-viewer">
				<PDFPreview
					v-if="pdf"
					v-show="$vuetify.breakpoint.mdAndUp"
					class="pdf-viewer__preview"
					:pages="pdfRenderedThumbnails"
					:scale="scale"
					:current-page="pageNumber"
					:total-items="totalPages"
					:is-preview-enabled="$vuetify.breakpoint.mdAndUp"
					@page-focus="setPageNumber"
				/>
				<PDFDocument
					v-if="pdf"
					class="pdf-viewer__document"
					:class="{ 'preview-enabled': $vuetify.breakpoint.mdAndUp }"
					:pages="pdfRenderedPages"
					:scale="scale"
					:current-page="pageNumber"
					:total-items="totalPages"
					:is-preview-enabled="$vuetify.breakpoint.mdAndUp"
					@page-focus="setPageNumber($event)"
					@pages-fetch="fetchPages()"
				/>
			</div>
		</v-card>
	</v-dialog>
</template>

<script>
import * as PDFJs from 'pdfjs-dist/webpack'

const MAX_RENDERED_PAGES = 10
const ZOOM_STEP = 0.25

export default {
	name: 'FilePreview',
	components: {
		PDFDocument: () => ({
			component: import('@/components/Commons/Files/Preview/PDFDocument')
		}),
		PDFPreview: () => ({
			component: import('@/components/Commons/Files/Preview/PDFPreview')
		})
	},
	props: {
		value: {
			required: true,
			type: Object
		}
	},
	data: function () {
		return {
			cursor: 0,
			dialog: false,
			loading: true,
			pageNumber: 1,
			pdf: null,
			pdfRenderedPages: [],
			pdfRenderedThumbnails: [],
			scale: 1,
			totalPages: 0
		}
	},
	created: function () {
		this.initialize()
	},
	mounted: function () {
		this.dialog = true
	},
	methods: {
		closeFilePreview: function () {
			this.dialog = false
			this.$emit('close')
		},
		/**
		 * @return {Promise<void>}
		 */
		initialize: function () {
			this.loading = true

			return new Promise(resolve => {
				let documentURL
				if (this.value.hasOwnProperty('version')) {
					documentURL = this.value.version.uri
				} else {
					documentURL = this.value.file
				}

				const { promise } = PDFJs.getDocument(documentURL)
				resolve(promise)
			})
				.then(pdf => {
					this.setPDF(pdf)
					this.clearFetchedThumbnails()
					this.clearFetchedPages()
					this.cursor = 0
					return this.fetchPages()
				})
				.then(() => {
					return this.fetchThumbnails()
				})
				.finally(() => {
					this.loading = false
				})
		},
		fetchThumbnails: function () {
			const addedThumbnailsPromises = []
			for (let i = 1; i <= this.totalPages; i++) {
				addedThumbnailsPromises.push(
					this.pdf.getPage(i).then(thumbnail => {
						if (!this.pdfRenderedThumbnails.some(renderedThumbnail => renderedThumbnail._pageIndex == thumbnail._pageIndex)) {
							this.pdfRenderedThumbnails.push(thumbnail)
						}
					})
				)
			}
			return Promise.all(addedThumbnailsPromises)
		},
		fetchPages: function () {
			const minIndex = Math.max(this.cursor == 0 ? this.pageNumber - 1 - MAX_RENDERED_PAGES / 2 : this.cursor, 0)
			const maxIndex = Math.min(minIndex + MAX_RENDERED_PAGES, this.totalPages)
			this.cursor = maxIndex
			const addedPagesPromises = []
			for (let i = 1; i <= this.totalPages; i++) {
				if ((minIndex <= i && i <= maxIndex) || this.pageNumber == i) {
					addedPagesPromises.push(
						this.pdf.getPage(i).then(newPage => {
							if (!this.pdfRenderedPages.some(renderedPage => renderedPage._pageIndex == newPage._pageIndex)) {
								this.pdfRenderedPages.push(newPage)
							}
						})
					)
				}
			}
			return Promise.all(addedPagesPromises)
		},
		clearFetchedThumbnails: function () {
			this.pdfRenderedThumbnails.clear()
		},
		clearFetchedPages: function () {
			this.pdfRenderedPages.clear()
		},
		setPageNumber: function (pageNumber) {
			this.pageNumber = pageNumber
			this.fetchPages()
		},
		setPDF: function (pdf) {
			this.pdf = pdf
			this.totalPages = pdf.numPages
			this.clearFetchedPages()
			this.clearFetchedThumbnails()
			this.fetchPages().then(this.fetchThumbnails())
		},
		updateScale: function ({ scale, isOptimal = false }) {
			const roundedScale = Math.floor(scale * 100) / 100
			if (isOptimal) {
				this.optimalScale = roundedScale
			}
			this.scale = roundedScale
		},
		zoomIn: function () {
			if (this.scale) {
				const newScaleValue = this.scale + ZOOM_STEP
				this.updateScale({ scale: newScaleValue, isOptimal: false })
			}
		},
		zoomOut: function () {
			if (this.scale && this.scale > ZOOM_STEP) {
				const newScaleValue = this.scale - ZOOM_STEP
				this.updateScale({ scale: newScaleValue, isOptimal: false })
			}
		}
	}
}
</script>

<style scoped>
.header-item {
	margin: 0 2.5em;
}

.pdf-viewer .pdf-viewer__document,
.pdf-viewer .pdf-viewer__preview {
	top: 64px;
}

.pdf-viewer__preview {
	display: block;
	width: 15%;
	right: 85%;
}

.pdf-viewer__document {
	top: 64px;
	width: 100%;
	left: 0;
}

.pdf-viewer__document.preview-enabled {
	width: 85%;
	left: 15%;
}
</style>
